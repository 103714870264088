import React, { useContext } from 'react';
import { myContext } from '../helper/context';
import { Link } from 'gatsby'
import * as styles from './searchbar.module.scss'

const SearchBar = (props) => {

    return (
        <section className={styles.searchbar}>
            Searchbar
        </section>
    )
}

export default SearchBar