import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
import bbox from "@turf/bbox"
import { multiPoint } from "@turf/helpers"
import Markers from "../components/markers"
import "mapbox-gl/dist/mapbox-gl.css"

// replace with your own Mapbox token
const MAPBOX_TOKEN = "pk.eyJ1IjoiZGFjaGFnZyIsImEiOiJjbDZlMnplZHMwYjAxM21teGx5eHFyanhiIn0.FsNLgXIbR7zsJOlkpqFjOA"

const mapContainerStyle = {
    width: "100%",
    height: "500px",
}

const Map = props => {
    const mapContainerRef = useRef(null)

    //console.log(props.featured)

    const [map, setMap] = useState(null)

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            accessToken: MAPBOX_TOKEN,
            style: "mapbox://styles/mapbox/satellite-v9",
            center: [-74.5, 40],
            zoom: 4,
        })

        //map.addControl(new mapboxgl.NavigationControl(), "top-right")

        setMap(map)

        return () => map.remove()
    }, [])

    useEffect(() => {
        if (!map) return

        if (props.featured && props.featured.length !== 0) {
            map.flyTo({
                center: [props.featured.lng, props.featured.lat],
                zoom: 16,
                essential: true,
                duration: 5000,
            }) 
        } else {
            const coords = []
            props.places.forEach(place => {
                coords.push([place.lng, place.lat])
            })
            const feature = multiPoint(coords)
            const box = bbox(feature)

            map.fitBounds(
                [
                    [box[0], box[1]],
                    [box[2], box[3]],
                ],
                {
                    padding: 80,
                    maxZoom: 14,
                    duration: 5000,
                }
            )
        }
    })

    useEffect(() => {
        //if (!map) return
        /*
        if (props.places && props.places.length !==0) {
            map.flyTo({
                center: [props.places[0].lng, props.places[0].lat],
                zoom: 13,
                //projection: 'globe',
                essential: true,
                //duration: 5000,
            })
        }
        */
        /*
        if (props.places && props.places.length !== 0) {
            const coords = []
            props.places.forEach(place => {
                coords.push([place.lng, place.lat])
            })
            const feature = multiPoint(coords)
            const box = bbox(feature)

            map.fitBounds(
                [
                    [box[0], box[1]],
                    [box[2], box[3]],
                ],
                {
                    padding: 80,
                    maxZoom: 14,
                    duration: 5000,
                    projection: 'globe',
                }
            )
        } else {
            map.easeTo({
                center: [-74.5, 40],
                zoom: 4,
                projection: 'globe'
            })
        }
        */
    }, [map, props.places])

    return (
        <div ref={mapContainerRef} style={mapContainerStyle}>
            {props.places && map && <Markers map={map} places={props.places} />}
        </div>
    )
}

export default Map