import React, { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import Header from '../components/header'
import SearchBar from '../components/searchbar'
import Map from '../components/map'
import * as styles from './search.module.scss'

const SearchPage = () => {

  //Set states
  const [locationData, setLocationData] = useState([])
  const [showMap, setShowMap] = useState(null)
  const [featuredData, setFeaturedData] = useState(null)

  useEffect(() => {
    //get search params
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);

    const lat = urlParams.get('lat')
    const lng = urlParams.get('lng')    

    // get data
    fetch(`https://data.golfip.com/wp-json/search/v1/search/?type=courses&lat=${lat}
&lng=${lng}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        //save the data
        setLocationData(resultData)
        //show the map now data has loaded
        setShowMap(true)

      }).catch((error) => {
        //navigate('/')
      })
  }, [])

  return (
    <div>
      <Header/>
      <SearchBar />
      <div className={styles.container}>
        <div className={styles.container__data}>
          {locationData.length ? <p>Yada</p> : <p>No Results</p>}
          {locationData.map(location => (<div className={styles.container__data__item} key={location.name} onMouseEnter={() => setFeaturedData({
            lng: location.lng,
            lat: location.lat,
          })} onMouseLeave={() => setFeaturedData()}>
          <Link to={`/courses/${location.slug}/`}>
              {location.distance}
              <h3 dangerouslySetInnerHTML={{ __html: location.name }} />
              {location.address}
          </Link>
        </div>))}
        </div>
        <div className={styles.container__map}>
          {showMap && <Map places={locationData} featured={featuredData} />}
        </div>
      </div>
    </div>
  )
}

export default SearchPage