import React, { useRef, useEffect } from "react"
import mapboxgl from "mapbox-gl"

const Marker = ({ map, place }) => {
    const markerRef = useRef()

    useEffect(() => {
        const marker = new mapboxgl.Marker(markerRef)
            .setLngLat([place.lng, place.lat])
            .addTo(map)

        return () => marker.remove()
    })

    return <div ref={markerRef} />
}

const Markers = ({ map, places }) => {
    return (
        <>
            {places &&
                places.map(place => (
                    <Marker key={place.name} map={map} place={place} />
                ))}
        </>
    )
}

export default Markers